import { ComponentChildren, createContext, Fragment, h, JSX } from 'preact';
import { useContext, useMemo, useState } from 'preact/hooks';
import { isNil, Nilable } from '@wistia/type-guards';
import { FormConfig, LiveEventConfig } from '../utilities/FormApi.ts';
import { LiveEventFormAnalyticsContext } from '../analytics/types.ts';
import type { RegistrationData } from '../types.ts';

type FormState = {
  embedHost?: Nilable<string>;
  formConfig: FormConfig;
  isPreview: boolean;
  isRedirectToEventOnRegisterEnabled: boolean;
  isRegistered: boolean;
  isWistiaPage?: boolean;
  liveEventConfig: LiveEventConfig;
  liveEventId: string;
};

const DEFAULT_FORM_STATE: FormState = {
  formConfig: {} as FormConfig,
  isRedirectToEventOnRegisterEnabled: true,
  isRegistered: false,
  isWistiaPage: false,
  liveEventConfig: {} as LiveEventConfig,
  liveEventId: '',
  isPreview: false,
};

type FormStateContextType = FormState & {
  analyticsContext: LiveEventFormAnalyticsContext;
  onRegister: (data: RegistrationData) => void;
  onRegistrationError: (error: Error) => void;
  refreshData: () => void;
  setIsRegistered: (value: boolean) => void;
};

const FormStateContext = createContext<FormStateContextType | null>(null);

type FormStateProviderProps = {
  children: ComponentChildren;
  initialState?: FormState;
  onRegister: (data: RegistrationData) => void;
  onRegistrationError: (error: Error) => void;
  refreshData: () => void;
};

export const useFormState = (): FormStateContextType => {
  const context = useContext(FormStateContext);

  if (isNil(context)) {
    throw new Error('useFormState must be used within a FormStateProvider');
  }

  return context;
};

export const FormStateProvider = ({
  children,
  initialState = DEFAULT_FORM_STATE,
  refreshData,
  onRegister,
  onRegistrationError,
}: FormStateProviderProps): JSX.Element => {
  const [isRegistered, setIsRegistered] = useState(initialState.isRegistered);

  const {
    account_id: accountId,
    media_id: mediaId,
    analytics_host: analyticsHost,
    anonymize_ips_for_analytics: shouldAnonymizeIp,
    live_stream_event_id: liveStreamEventId,
    is_live_for_analytics: isLiveForAnalytics,
  } = initialState.liveEventConfig;

  const sessionKey = useMemo(() => {
    return window.Wistia?.VisitorKey.generate() ?? '';
  }, []);

  const analyticsContext: LiveEventFormAnalyticsContext = {
    sessionKey,
    accountId,
    liveStreamEventId,
    analyticsHost,
    mediaId,
    shouldAnonymizeIp,
    isLiveForAnalytics,
  };

  return (
    <FormStateContext.Provider
      value={{
        ...initialState,
        isRegistered,
        setIsRegistered,
        refreshData,
        onRegister,
        analyticsContext,
        onRegistrationError,
      }}
    >
      <Fragment>{children}</Fragment>
    </FormStateContext.Provider>
  );
};

import { isNotNil, isNonEmptyArray } from '@wistia/type-guards';
import { getVisitorKey } from '../utilities/localStorageHelpers.ts';
import { isFirstEvent, sendAnalyticsEvent } from './helpers.ts';
import {
  AnalyticsData,
  Event,
  FormLoadedAnalyticsEvent,
  FormRegisteredAnalyticsEvent,
  LiveEventFormAnalyticsContext,
  Scope,
} from './types.ts';
import { FormFieldData, LiveTrackerRegistration } from '../utilities/FormApi.ts';

type FormLoadedAnalyticsProps = {
  event?: Event;
  isFirst: boolean;
  isLive: boolean;
  scope?: Scope;
  time?: number;
};

type FormRegisteredAnalyticsProps = {
  company?: string;
  country?: string;
  email: string;
  event: Event;
  first_name: string;
  form_field_data?: FormFieldData[];
  isFirst: boolean;
  isLive: boolean;
  job_title?: string;
  last_name: string;
  phone?: string;
  scope: Scope;
  time: number;
};

const buildLoadedEvent = ({
  time = Date.now(),
  event = 'load',
  scope = 'registration',
  isFirst = true,
  isLive = true,
}: FormLoadedAnalyticsProps): FormLoadedAnalyticsEvent => ({
  time,
  event,
  scope,
  first: isFirst,
  live: isLive,
});

const buildRegisteredEvent = ({
  time = Date.now(),
  event = 'register',
  scope = 'registration',
  isFirst = true,
  isLive = true,
  first_name,
  last_name,
  email,
  company,
  job_title,
  phone,
  country,
  form_field_data,
}: FormRegisteredAnalyticsProps): FormRegisteredAnalyticsEvent => {
  const registeredEvent: FormRegisteredAnalyticsEvent = {
    time,
    event,
    scope,
    live: isLive,
    first: isFirst,
    first_name,
    last_name,
    email,
  };

  if (isNotNil(company)) {
    registeredEvent.company = company;
  }

  if (isNotNil(job_title)) {
    registeredEvent.job_title = job_title;
  }

  if (isNotNil(phone)) {
    registeredEvent.phone = phone;
  }

  if (isNotNil(country)) {
    registeredEvent.country = country;
  }

  if (isNonEmptyArray(form_field_data)) {
    registeredEvent.form_field_data = form_field_data;
  }

  return registeredEvent;
};

const buildAnalyticsData = (
  analyticsContext: LiveEventFormAnalyticsContext,
  analyticsEvent: FormLoadedAnalyticsEvent | FormRegisteredAnalyticsEvent,
): AnalyticsData => {
  const {
    sessionKey: session_key,
    accountId: account_id,
    mediaId: media_id,
    shouldAnonymizeIp: anonymize_ip,
    liveStreamEventId: live_stream_event_id,
  } = analyticsContext;

  return {
    account_id,
    embed_url: window.location.href,
    events: [analyticsEvent],
    live_stream_event_id,
    media_id,
    media_type: 'Video',
    player_type: 'liveRegistrationFormEmbed',
    referrer_url: document.referrer,
    session_key,
    anonymize_ip,
    visitor_key: getVisitorKey(),
  };
};

export const FormAnalyticsApi = {
  trackFormLoaded: async (analyticsContext: LiveEventFormAnalyticsContext): Promise<void> => {
    const { analyticsHost, isLiveForAnalytics, liveStreamEventId } = analyticsContext;

    const analyticsEvent = buildLoadedEvent({
      isLive: isLiveForAnalytics,
      isFirst: isFirstEvent({
        liveStreamEventId,
        eventType: 'load',
      }),
    });

    await sendAnalyticsEvent(analyticsHost, buildAnalyticsData(analyticsContext, analyticsEvent));
  },
  trackFormSubmitted: async (
    analyticsContext: LiveEventFormAnalyticsContext,
    registration: LiveTrackerRegistration,
  ): Promise<void> => {
    const { liveStreamEventId, analyticsHost, isLiveForAnalytics } = analyticsContext;

    const {
      first_name,
      last_name,
      email,
      company,
      country,
      job_title,
      phone_number: phone,
      form_field_data,
    } = registration;

    const analyticsEvent = buildRegisteredEvent({
      company,
      country,
      email,
      event: 'register',
      first_name,
      form_field_data,
      isFirst: isFirstEvent({
        liveStreamEventId,
        eventType: 'register',
      }),
      isLive: isLiveForAnalytics,
      job_title,
      last_name,
      phone,
      scope: 'registration',
      time: Date.now(),
    });

    await sendAnalyticsEvent(analyticsHost, buildAnalyticsData(analyticsContext, analyticsEvent));
  },
};

import { isEmptyRecord, isEmptyString, isNil, Nilable, Nullable } from '@wistia/type-guards';
import {
  ANALYTICS_EVENT_LOCAL_STORAGE_KEY,
  REGISTRATION_LOCAL_STORAGE_KEY,
  WISTIA_LOCAL_STORAGE_KEY,
} from './constants.ts';
import {
  LiveStreamLocalStorageAnalyticEvents,
  LocalStorageAnalyticEvent,
  LocalStorageRegistrationInfo,
} from '../types.ts';
import { getLocalStorage, setLocalStorage } from '../../../utilities/namespacedLocalStorage.js';

export const getRegistrations = (): LocalStorageRegistrationInfo[] => {
  const registrationInfo = getLocalStorage(REGISTRATION_LOCAL_STORAGE_KEY) as
    | LocalStorageRegistrationInfo[]
    | Record<string, never>;

  if (Array.isArray(registrationInfo)) {
    return registrationInfo;
  }

  return [];
};

export const getRegistrationByEventId = (
  eventId: string,
): Nilable<LocalStorageRegistrationInfo> => {
  const registrations = getRegistrations();

  return registrations.find((registration) => registration.eventId === eventId);
};

export const setRegistrationInfo = (registrationInfo: LocalStorageRegistrationInfo): void => {
  const registrations = getRegistrations();

  registrations.push(registrationInfo);

  setLocalStorage(REGISTRATION_LOCAL_STORAGE_KEY, registrations);
};

export const getVisitorKey = (): string => {
  const wistiaStorage = getLocalStorage(WISTIA_LOCAL_STORAGE_KEY) as Record<string, unknown>;
  let visitorKey = wistiaStorage.__distillery as Nullable<string>;

  if (isNil(visitorKey) || isEmptyString(visitorKey)) {
    visitorKey = window.Wistia?.VisitorKey.generate() ?? '';
    setLocalStorage(WISTIA_LOCAL_STORAGE_KEY, {
      ...wistiaStorage,
      __distillery: visitorKey,
    });
  }

  return visitorKey;
};

export const getAnalyticEvents = (): Nullable<Record<number, LocalStorageAnalyticEvent>> => {
  const value = window.localStorage.getItem(ANALYTICS_EVENT_LOCAL_STORAGE_KEY);

  if (isNil(value) || isEmptyString(value) || isEmptyRecord(value)) {
    return null;
  }

  return JSON.parse(value) as Record<number, LocalStorageAnalyticEvent>;
};

export const setAnalyticEvent = ({
  eventId,
  event,
}: LiveStreamLocalStorageAnalyticEvents): void => {
  const events = getAnalyticEvents() ?? {};
  events[eventId] = event;
  window.localStorage.setItem(ANALYTICS_EVENT_LOCAL_STORAGE_KEY, JSON.stringify(events));
};

import { isNil, isNotNil } from '@wistia/type-guards';
import { AnalyticsData, Event } from './types.ts';
import { getAnalyticEvents, setAnalyticEvent } from '../utilities/localStorageHelpers.ts';
import { LocalStorageAnalyticEvent } from '../types.ts';
import { wlog } from '../../../utilities/wlog.js';

const getOrInitializeEvent = (eventId: number): LocalStorageAnalyticEvent => {
  const events = getAnalyticEvents();

  if (isNil(events) || isNil(events[eventId])) {
    return { load: false, register: false };
  }

  return events[eventId];
};

export const isFirstEvent = ({
  liveStreamEventId,
  eventType,
}: {
  eventType: Event;
  liveStreamEventId: number;
}): boolean => {
  const event = getOrInitializeEvent(liveStreamEventId);
  if (isNotNil(event[eventType]) && Boolean(event[eventType])) {
    return false;
  }

  event[eventType] = true;
  setAnalyticEvent({ eventId: liveStreamEventId, event });
  return true;
};

export const sendAnalyticsEvent = async (
  analyticsHost: string,
  analyticEvent: AnalyticsData,
): Promise<void> => {
  await fetch(`${analyticsHost}/live-stream/1.0.0`, {
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
    method: 'POST',
    body: JSON.stringify(analyticEvent),
    mode: 'cors',
  }).catch((error: unknown) => {
    wlog.error(`Failed to track event: ${(error as string).toString()}`);
  });
};
